
import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
  candidatesPersonalInfo: any = {};
  public candidatesId = 0;
  public username = "";
  public token = "";

  constructor() { }

  setCandidatePersonalInfo(data) {
    this.candidatesPersonalInfo = data;
  }

  getCandidatePersonalInfo() {
    return this.candidatesPersonalInfo;
  }


}
