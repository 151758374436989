import { Injectable } from "@angular/core";

@Injectable()
export class UrlServices {
    // api = 'http://10.10.10.64:8094';
    // api = 'http://localhost:8091';
    // api = 'https://rekrutaja.anteraja.id/admin/api';
    //  api = 'http://34.87.6.140:8091'
    // api = 'http://35.187.237.86/psikotest';
    // api = 'https://anteraja.id/api/psikotest';
    api = 'https://rekrutaja-staging.anteraja.id/admin/api';
    apiHarmonyS3: string = 'https://harmony-finance-sit.anteraja.id/harmony/s3Harmony';

  // disc = 'http://localhost:8091';
  // disc = 'https://rekrutaja.anteraja.id/admin/api';
  //  disc = 'http://34.87.6.140:8091'
  // disc = 'http://35.187.237.86/disc';
  // disc = 'https://anteraja.id/api/disc';

  /* sit https://rekrutaja-sit.anteraja.id/ or prod*/
  reCaptcha = "6LcKj8YZAAAAAKuqXKYTokxkJ7Dh0IMAMcx_a9xU";
  /* local or http://34.87.6.140:8092/ */
  // reCaptcha = '6LfipgkaAAAAAEziCALEB3wGne3iMOhHrYy7qhcU';
  // reCaptcha = '6LeokqwZAAAAANyd5SfAwTSZPftbyTHjgtHlpQ9e';

  constructor() {
    let url = window.location.hostname;

    if (url == "rekrutaja.anteraja.id" || url == "rekrutaja-dev1.anteraja.id") {
      this.api = "https://rekrutaja.anteraja.id/admin/api";
      this.apiHarmonyS3 = 'https://harmony.anteraja.id/harmony/s3Harmony';
    } else {
      this.api = "https://rekrutaja-staging.anteraja.id/admin/api";
      this.apiHarmonyS3 = 'https://harmony-finance-sit.anteraja.id/harmony/s3Harmony';
    }

    if (url == "rekrutaja.anteraja.id")
      this.reCaptcha = "6LcKj8YZAAAAAKuqXKYTokxkJ7Dh0IMAMcx_a9xU";
    else this.reCaptcha = "6LfipgkaAAAAAEziCALEB3wGne3iMOhHrYy7qhcU";
  }
}
