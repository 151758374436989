import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CustomPopupComponent } from "./custom-popup.component";

@Injectable({
  providedIn: "root",
})
export class CustomPopupService {
  constructor(public dialog: MatDialog) {}

  confirm(text = "") {
    return this.openDialog("confirm", text);
  }

  success(text = "") {
    return this.openDialog("success", text);
  }

  fail(text = "") {
    return this.openDialog("fail", text);
  }

  private openDialog(type: string, text: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      type: type,
      text: text,
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, dialogConfig);
    return dialogRef;
  }
}
