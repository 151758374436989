import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardCandidate } from "./services/auth-guard-candidate";

const routes: Routes = [
    {
        path: "",
        loadChildren: () => import('./main-page/main-page.module').then(m => m.MainPageModule),
    },
    {
        path: "LMS",
        loadChildren: () => import('./lms/lms.module').then(m => m.LMSModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "vacancy/:id",
        loadChildren: () => import('./vacancy-page/vacancy-page.module').then(m => m.VacancyPageModule),
    },
    {
        path: "vacancy-form/:id",
        loadChildren: () => import('./vacancy-form/vacancy-form.module').then(m => m.VacancyFormModule),
    },
    {
        path: "psikotest-start",
        loadChildren: () => import('./vacancy-form/vacancy-form.module').then(m => m.VacancyFormModule),
        // loadChildren: './psikotest-start/psikotest-start.module#PsikotestStartModule'
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "epkwt-form",
         loadChildren: () => import('./vacancy-form/vacancy-form.module').then(m => m.VacancyFormModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-tui",
        loadChildren: () => import('./psikotest-tui/psikotest-tui.module').then(m => m.PsikotestTuiModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-verbal",
        loadChildren: () => import('./psikotest-verbal/psikotest-verbal.module').then(m => m.PsikotestVerbalModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-disc",
        loadChildren: () => import('./psikotest-disc/psikotest-disc.module').then(m => m.PsikotestDiscModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "login",
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: "psikotest-tiu6",
        loadChildren: () => import('./psikotest-tiu6/psikotest-tiu6.module').then(m => m.PsikotestTiu6Module),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-tiki1",
        loadChildren: () => import('./psikotest-tiki1/psikotest-tiki1.module').then(m => m.PsikotestTiki1Module),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-tiki2",
        loadChildren: () => import('./psikotest-tiki2/psikotest-tiki2.module').then(m => m.PsikotestTiki2Module),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-tiki3",
        loadChildren: () => import('./psikotest-tiki3/psikotest-tiki3.module').then(m => m.PsikotestTiki3Module),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-tiki4",
        loadChildren: () => import('./psikotest-tiki4/psikotest-tiki4.module').then(m => m.PsikotestTiki4Module),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-grit",
        loadChildren: () => import('./psikotest-grit/psikotest-grit.module').then(m => m.PsikotestGritModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-kreplin",
        loadChildren: () => import('./psikotest-kreplin/psikotest-kreplin.module').then(m => m.PsikotestKreplinModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "psikotest-papikostik",
        loadChildren: () => import('./psikotest-papikostik/psikotest-papikostik.module').then(m => m.PsikotestPapikostikModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "epkwt",
        loadChildren: () => import('./e-pkwt/e-pkwt.module').then(m => m.EpkwtModule),
        canActivate:[AuthGuardCandidate]
    },{
        path: "psikotest-end",
        loadChildren: () => import('./psikotest-end/psikotest-end.module').then(m => m.PsikotestEndModule),
    },
    {
        path: "mitra",
        loadChildren: () => import('./mitra/mitra.module').then(m => m.MitraModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "post-test",
        loadChildren: () => import('./post-test/post-test.module').then(m => m.PostTestModule),
        canActivate:[AuthGuardCandidate]
    },
    {
        path: "kj4up/:code",
        loadChildren: () => import('./kj4up/kj4up.module').then(m => m.Kj4upModule),
    },
    {
      path: "interview-form/:code",
      loadChildren: () => import('./interview-form/interview-form.module').then(m => m.InterviewFormModule),
  },

    { path: '**', redirectTo: '' }
]

@NgModule({
    imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
    exports: [],
  })
export class AppRoutingModule {}
