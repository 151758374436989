import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.css']
})
export class CustomPopupComponent implements OnInit {

  title = "";
  picPath = "";
  buttonText = "";
  descriptionText = '';
  confirmButtons = false;
  showIG = false;
  
  constructor(private dialogRef: MatDialogRef<CustomPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

      const picFolder = 'assets/img/UI/';

      const type = data.type;

      switch (type) {
        case 'success':
          this.title = 'Process berhasil dilakukan!';
          this.picPath = picFolder + 'success-symbol.png';
          this.buttonText = 'BelajarAja';
          this.showIG = true;
          break;

        case 'confirm':
          this.title = 'Apakah kamu yakin untuk kirim?';
          this.picPath = picFolder + 'warning-symbol.png';
          this.confirmButtons = true;
        break;

        case 'fail':
          this.title = 'Oops, process gagal dilakukan!';
          this.picPath = picFolder + 'failed-symbol.png';
          
          let desc = 'Cek koneksi internet anda secara berkala dan silahkan ulangi proses.'
          if (data.text.length > 0) desc = data.text;
          this.descriptionText = desc;
          this.buttonText = 'Ulangi Proses'
          break;
      
        default:
          break;
      }
     }

  ngOnInit() {
  }

  confirm(){
    this.dialogRef.close(true);
  }

  close(){
    this.dialogRef.close(false);
  }

}
