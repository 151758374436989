import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlServices } from "./url-services";
import { map, catchError, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, pipe } from "rxjs";
import * as CryptoJS from "crypto-js";

@Injectable()
export class AuthService {
  constructor(public http: HttpClient, public urlServices: UrlServices) {}

  login(username, password) {
    let url =
      this.urlServices.api +
      "/users/signinpsikotest?username=" +
      username +
      "&password=" +
      password;

    return this.http.post(url, null).pipe(
      map(this.extractData),
      catchError(this.handleError),
      tap((res) => {
        this.setSession(res);
        localStorage.setItem("username", username);
      })
    );
  }

  logineasy(phoneNumber, password, recaptcha, status) {
    let form = new FormData();
    form.append("phoneNumber", phoneNumber);
    form.append("password", password);
    form.append("recaptchaResponse", recaptcha);
    form.append("status", status);

    let url = this.urlServices.api + "/candidates/logincandidatesV2";

    return this.http.post(url, form).pipe(
      map(this.extractData),
      catchError(this.handleError),
      tap((res) => {
        localStorage.setItem("phoneNumber", phoneNumber);
      })
    );
  }

  loginWithEncryptedData(phoneNumber, password, recaptcha, status) {
    let url = this.urlServices.api + "/candidates/logincandidates";
    const data = {
      username: phoneNumber,
      password: password,
      recaptchaResponse: recaptcha,
      status: status,
    };
    const body = { username: data.username };
    const bodyJson = JSON.stringify(body);
    const base64String = btoa(bodyJson);
    const encrypt = encodeURIComponent(
      CryptoJS.AES.encrypt(JSON.stringify(data), base64String).toString()
    );
    const request = {
      data: encrypt,
      token: base64String,
    };

    return this.http.post(url, request).pipe(
      map(this.extractData),
      catchError(this.handleError),
      tap((res) => {
        localStorage.setItem("phoneNumber", phoneNumber);
        this.setSession(res.token);
      })
    );
  }

  checkEpkwt(candidateId, docType) {
    let url;
    if (docType.toLowerCase().includes("asl")) {
      url = `${this.urlServices.api}/epkwt/getDetailMitraAsl?candidatesId=${candidateId}`;
    } else if (docType.toLowerCase().includes("project")) {
      url = `${this.urlServices.api}/epkwt/getDetailMitraProject?candidatesId=${candidateId}`;
    } else if (docType.toLowerCase().includes("mitra")) {
      url = `${this.urlServices.api}/epkwt/getDetailMitra?candidatesId=${candidateId}`;
    } else {
      url = `${this.urlServices.api}/epkwt/getDetail?candidatesId=${candidateId}`;
    }

    return this.http
      .get(url)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  nextPath(candidateId) {
    let url =
      this.urlServices.api + "/header/findnext?candidateId=" + candidateId;

    return this.http
      .get(url, { responseType: "text" })
      .pipe(catchError(this.handleError));
  }

  public getClientIP() {
    let url = "https://api.ipify.org/?format=json";

    return this.http
      .get(url)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  public canAccess() {
    this.getClientIP();
  }

  public setSession(authResult) {
    // const expiresAt = moment().add(authResult.expiresIn, 'second');
    if (authResult.result) {
      localStorage.setItem("token", authResult.result);
    } else {
      localStorage.setItem("token", authResult);
    }
    // localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("expires_at");
  }

  // public isLoggedIn() {
  //     return moment().isBefore(this.getExpiration());
  // }

  // isLoggedOut() {
  //     return !this.isLoggedIn();
  // }

  // getExpiration() {
  //     const expiration = localStorage.getItem("expires_at");
  //     const expiresAt = JSON.parse(expiration);
  //     console.log(moment(expiresAt));
  //     return moment(expiresAt);

  // }

  private extractData(body: any) {
    return Object.assign(body.data || body);
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg: string;
    let errObj: any;

    if (error instanceof HttpErrorResponse) {
      const err = error.message || JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
      errObj = error.error;
    } else {
      errMsg = error.message ? error.message : error.toString();
      const body = error.message || "";
      errObj = body;
    }

    return throwError(errObj);
  }
}
