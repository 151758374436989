import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "../material-modules/material-module";
import { NgxSpinnerModule } from "ngx-spinner";
import { CountdownModule } from "ngx-countdown";
import { ngxLoadingAnimationTypes, NgxLoadingModule } from "ngx-loading";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatStepperModule } from "@angular/material/stepper";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatDividerModule } from "@angular/material/divider";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";

@NgModule({
  imports: [
    CommonModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatStepperModule,
    MatSnackBarModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MaterialModule,
    NgxSpinnerModule,
    CountdownModule,
    NgxLoadingModule.forRoot({
      backdropBorderRadius: "3px",
      fullScreenBackdrop: true,
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0, 0, 0, 0.5)",
    }),
  ],
  declarations: [],
  exports: [
    CommonModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatStepperModule,
    MatSnackBarModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MaterialModule,
    NgxSpinnerModule,
    CountdownModule,
    NgxLoadingModule,
  ],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
