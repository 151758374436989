<nav [hidden]="!navbarService.visible" class="navbar fixed-top navbar-transparent">
    <div class="container">
        <div class="navbar-translate">
            <div class="logo-container">
                <a href="https://rekrutaja.anteraja.id/">
                    <img class="" src="assets/img/UI/rekrutaja-logo-white.png" alt="Rekrutaja Logo">
                </a>
            </div>
        </div>
        <div class="ml-auto">
            <div class="navbar-translate">
                <div class="logo-container">
                    <a href="https://anteraja.id/">
                        <img src="assets/img/UI/anteraja-logo-white.png" alt="" srcset="Anteraja Logo">
                    </a>
                </div>
            </div>
        </div>
    </div>
</nav>
