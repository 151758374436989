import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPopupComponent } from './custom-popup.component';
import { CustomPopupService } from './custom-popup.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [CustomPopupComponent],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [CustomPopupComponent],
  bootstrap: [CustomPopupComponent],
  providers: [CustomPopupService]
})
export class CustomPopupModule { }
