<h2 mat-dialog-title align="center">{{ title }}</h2>

<mat-dialog-content class="">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <img [src]="picPath" alt="Popup Logo" class="img-responsive" />
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <p *ngIf="descriptionText.length > 0" class="">{{ descriptionText }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <p *ngIf="showIG" class="">follow instagram kami :</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="d-flex mx-auto" *ngIf="showIG">
        <img src="assets/img/UI/ig-logo.png" alt="Ig logo" class="mr-2" />
        <img src="assets/img/UI/lifeaja-text.png" alt="lifeaja" />
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="confirmButtons; else normalButton">
    <button class="btn btn-lg btn-pink-outline mr-2" (click)="close()">Tidak</button>
    <button class="btn btn-lg btn-pink" (click)="confirm()">Ya</button>
  </ng-container>
  <ng-template #normalButton>
    <button class="btn btn-lg btn-pink" (click)="close()">
      {{ buttonText }}
    </button>
  </ng-template>
</mat-dialog-actions>
