import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./material-modules/material-module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { UrlServices } from "./services/url-services";
import { HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { CountdownModule } from "ngx-countdown";
import { NavbarService } from "./services/navbar.service";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import { APP_BASE_HREF, DatePipe, DecimalPipe } from "@angular/common";
import { AuthGuard } from "./services/auth-guard.service";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app.routing";
import { SharedModule } from "./services/shared.module";
import { AuthGuardCandidate } from "./services/auth-guard-candidate";
import { CustomPopupModule } from "./components/custom-popup/custom-popup.module";
import { CustomPopupService } from "./components/custom-popup/custom-popup.service";
import { NgHttpLoaderModule } from "ng-http-loader";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { StorageServiceModule } from "ngx-webstorage-service";
@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSpinnerModule,
    StorageServiceModule,
    CountdownModule,
    SharedModule,
    NgxLoadingModule.forRoot({
      backdropBorderRadius: "3px",
      fullScreenBackdrop: true,
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0, 0, 0, 0.5)",
    }),
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CustomPopupModule,
    NgHttpLoaderModule,
    HammerModule,
  ],
  providers: [
    UrlServices,
    AuthService,
    DataService,
    NavbarService,
    AuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    DatePipe,
    DecimalPipe,
    AuthGuardCandidate,
    CustomPopupService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
