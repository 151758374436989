import { Inject, Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import { DOCUMENT } from '@angular/common';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(@Inject(DOCUMENT) private document: any, private authService: AuthService, private router: Router) {};

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getClientIP().toPromise().then(e => {
      if (e['ip'] == '117.54.231.50' || e['ip'] == '103.105.66.226' || e['ip'] == '117.54.114.74') {
        return true;
      } else {
        this.redirectToExternalUrl();
        return false;
      }
    })
    // if (this.authService.isLoggedIn()) {
    //   return true;
    // } else {
    //   this.router.navigateByUrl('/login');
    //   this.authService.logout();
    //   return false;
    // }
  }

  redirectToExternalUrl() {
    this.document.location.href="https://www.anteraja.id";
  }
}
