import { Injectable, Inject } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { DOCUMENT } from "@angular/common";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { NavbarService } from "./navbar.service";

@Injectable()
export class AuthGuardCandidate implements CanActivate {
  constructor(
    private navbarService: NavbarService,
    private authService: AuthService,
    private router: Router,
    @Inject(SESSION_STORAGE) public storage: StorageService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let id = this.storage.get("candidate_id");
    let documentType = this.storage.get("documentType");
    // console.log("Guard")
    // console.log(state.url)
    // console.log("id :"+id)
    //return true

    // Show navbar conditionally
    if(state.url == '/login') this.navbarService.hide();
    else this.navbarService.show();

    if (id == null) {
      //  console.log("empty")
      this.router.navigateByUrl("/login");
      return false;
    }
    if (state.url == "/epkwt") {
      //console.log("operning epkwt page")

      this.authService.checkEpkwt(id,documentType).subscribe(
        (result) => {
          return true;
        },
        (err) => {
          this.storage.remove("candidate_id");
          this.router.navigateByUrl("/login");
          return false;
        }
      );
    }
    if (state.url.includes("psikotest")) {
      //  console.log("operning psikotest page")
      this.authService.nextPath(id).subscribe(
        (result) => {
          //console.log("check next path : "+result)
          if (result == "/psikotest-end") {
            this.storage.remove("candidate_id");
            this.router.navigateByUrl("/login");
            return false;
          }
          return true;
        },
        (err) => {
          this.storage.remove("candidate_id");
          return false;
        }
      );
    }

    return true;
  }
}
